.frame {
    text-align: center;
    background-color: #e46d35;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
  }
  
  .logo {
    height: 40vmin;
    pointer-events: none;
  }

  .header {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: rgb(84, 80, 80);
    border-radius: 30px;
    border-style: inset;
    border-width: thick;
    position: relative;
    max-width: 33%;
    max-height: 60%;
    top: 20%;
    left: 33%;
  }