.header {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #77002e;
  padding: 0 5%;
}

.logo {
  max-width: 100%;
  max-height: 100%;
}

.logoDiv {
  display: flex;
  width: 15%;
  height: 70%;
  top: 0%;
  background-color: #fcf3f6;
  border-radius: 10px;
  justify-content: center;
}

.btn {
  padding: 10px;
  font-size: 1.5rem;
  margin-top: 10px;
  border: none;
  color: #fcb8d2;
  background-color: transparent;
}

.btn:hover {
  color: white;
}

.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
  justify-content: right;
}

.header li {
  margin-left: 3rem;
}

.header a {
  text-decoration: none;
  font-size: 1.5rem;
  color: #fcb8d2;
}

.header a:hover,
.header a:active,
.header a.active {
  color: white;
}

.nav {
  width: 45%;
}
