.backDiv {
    display: flex;
    justify-content: space-between;
    width: 1/3;
    max-width: 100%;
}

.tipButton {
    outline: none;
    border: none;
    background-color: white;
    width: 24px;
    height: 24px;
}

.tipButton:hover{
    transform: scale(1.1);
}

.totalTip {
    height: 250px;
    width:150px; 
    overflow: auto;
}

.tipImage {
    width: 24px;
    height: 24px;
    padding-bottom: 10%;
}
