.menu {
    list-style: none;
    padding-top: 10px;
}

.icon {
    display: flex;
    width: 20px;
}

.outerFlex {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.menuFlex {
    display: inline-table;
    justify-content: space-between;
    align-items: baseline;
}
.innerFlex {
    display: flex;
    justify-content: center;
    width: 1/5;
    align-items: flex-start;
}

.innerFlex div{
    width: 1/3;
}

.span {
    line-height: 25px;
    display: table-cell;
    vertical-align: top;
}